<template>
  <div class="layout-box" :class="{ paddintBtm50: tabShow }" v-if="isLogin">
    <router-view></router-view>
    <app-Tabbar v-if="tabShow"></app-Tabbar>
    <van-dialog v-model="show" :before-close="beforeClose" @confirm="confirm">
      <div class="feedback-dialog-box">
        <div class="feedback-cycle-setting">
          <span>反馈周期配置：</span>
          <van-field v-model="digit" class="day" type="digit">
            <template #button>
              <img class="up" @click="up" src="../../assets/common/up.png" alt />
              <img class="down" @click="down" src="../../assets/common/down.png" alt />
            </template>
          </van-field>
          <span>天</span>
        </div>
        <div class="feedback-cycle-tip">反馈周期用于简历状态停留时，超过这个时间，将会通过邮件或短信的方式提醒，你应该及时处理了</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'layout',
  data() {
    return {
      tabShow: true,
      show: false,
      digit: '',
    };
  },
  computed: {
    ...mapState('user', {
      isLogin: (state) => state.isLogin,
    }),
  },
  async mounted() {
    if (localStorage.getItem('accessToken')) {
      this.SET_ISLOGIN(true);
    } else {
      this.SET_ISLOGIN(false);
      localStorage.setItem('userInfo', '');
      localStorage.setItem('accessToken', '');
      this.$router.push('/login');
    }
    let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
    if (back.code === 200) {
      localStorage.setItem('userInfo', JSON.stringify(back.data));
      let userInfo = back.data
      if (userInfo.approveCodeEnum !== 'PROCESS_DONE') {
        this.tabShow = false;
        let urlPath = null;
        switch (back.data.approveCodeEnum) {
          case 'UN_LINK':
            urlPath = '/noLegalize';
            break;
          case 'APPROVE_FAIL':
            urlPath = '/noPassAudit';
            break;
          case 'SECONED_WAITTING':
            urlPath = '/auditing';
            break;
          case 'WAITING':
            urlPath = '/auditing';
            break;
          case 'APPROVE_PASS':
            urlPath = '/passAudit';
            break;
          case 'COMPANY_STOP':
            urlPath = '/login';
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$toast('公司被禁用');
            break;
          case 'STOP':
            urlPath = '/login';
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$toast('账号禁用');
            break;
        }
        this.$router.push({ path: urlPath });
      } else {
        if (this.$root.isInMini) {
          this.tabShow = false;
        } else {
          this.tabShow = true;
        }
        if (userInfo.feedbackCycleDays === null) {
          this.show = true;
        }
      }
    } else {
      this.SET_ISLOGIN(false);
      localStorage.setItem('accessToken', '');
      localStorage.setItem('userInfo', '');
      this.$router.push('/login');
      return;
    }
  },
  methods: {
    ...mapMutations('user', ['SET_ISLOGIN']),
    beforeClose(action, done) {
      if (action === 'confirm') {
        done(false);
      }
    },
    up() {
      if (this.digit < 30) {
        this.digit++;
      }
    },
    down() {
      if (this.digit > 0) {
        this.digit--;
      }
    },
    confirm() {
      if (!this.digit) {
        this.$toast('反馈周期未配置天数，请检查！');
        return;
      } else if (this.digit <= 0 || this.digit > 30) {
        this.$toast('反馈周期未配置天数为正整数,且最大为30天');
        return;
      } else {
        this.$http
          .post(
            '/selfhelpOfficialAccounts/users/feedback?feedbackCycle=' +
            this.digit,
            {
              feedbackCycle: this.digit,
            }
          )
          .then(async (res) => {
            if (res.code === 200) {
              let back = await this.$http.get(
                '/selfhelpOfficialAccounts/relation'
              );
              if (back.code === 200) {
                localStorage.setItem('userInfo', JSON.stringify(back.data));
                this.userInfo = back.data;
                this.show = false;
                this.$toast('设置成功');
              }
            } else {
              this.$toast(res.message || '设置失败');
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-box {
  height: 100%;
  width: 100%;
}
.paddintBtm50 {
  padding-bottom: 50px;
}
.feedback-dialog-box {
  padding: 25px 35px;
}
.feedback-cycle-setting {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  .day {
    padding: 0;
    width: 54px;
    margin-right: 10px;
    .van-field__body {
      position: relative;
    }
    ::v-deep .van-field__control {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #206cfe;
      height: 24px;
      width: 54px;
      border-radius: 2px;
      border: 1px solid #e5e5e5;
      padding: 2px 12px 2px 5px;
    }
    ::v-deep .van-field__button {
      padding-left: 0;
    }
    img {
      position: absolute;
    }
    .up {
      top: 2px;
      right: 1px;
      height: 10px;
      width: 10px;
    }
    .down {
      bottom: 2px;
      right: 1px;
      height: 10px;
      width: 10px;
    }
  }
}
.feedback-cycle-tip {
  margin-top: 9px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
}
</style>
